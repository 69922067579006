import React, { useContext, useState, useEffect, useRef } from 'react';
import { useCryptoState } from '../../CryptoCtx';
import { ChatContext } from '../../context/ChatContext';
import { useFetchRecipientUser } from '../../hooks/useFetchRecipient';
import { Stack } from 'react-bootstrap';
import moment from 'moment';
import InputEmoji from 'react-input-emoji';
import axios from 'axios';
import { baseUrl } from '../../utils/services'; // Ensure this path is correct
const ChatBox = ({ chatId,itemId }) => {
  const { user } = useCryptoState();
  const { currentChat, messages, isMessageLoading, sendTextMessage } = useContext(ChatContext);
  const { recipientUser } = useFetchRecipientUser(currentChat, user);
  const [textMessage, setTextMessage] = useState("");
  const [image, setImage] = useState(null); // State to hold the selected image file
  const [coinInfo, setCoinInfo] = useState(null);
  const scroll = useRef();
  const [coinName, setcoinName] = useState('')
  const [coinStatus, setcoinStatus] = useState('')
  const [isSending, setIsSending] = useState(false)


  const [recipientAccounts, setRecipientAccounts] = useState([]);
  const [accountData, setAccountData] = useState({});

  useEffect(() => {
    const fetchRecipientAccounts = async () => {
      if (coinStatus === 'open' && recipientUser) {
        try {
          const response = await axios.get(`${baseUrl}/auth/accounts/${recipientUser?.aid}`);
          console.log('Recipient Accounts:', response.data); // Log account details
                 const accounts = response.data;

        // Group accounts by type (bank, wallet)
        const categorizedAccounts = accounts.reduce((acc, account) => {
          const { type } = account;
          if (!acc[type]) {
            acc[type] = [];
          }
          acc[type].push(account);
          return acc;
        }, {});

        setAccountData(categorizedAccounts);
        } catch (error) {
          console.error('Error fetching recipient accounts:', error);
        }
      }
    };

    fetchRecipientAccounts();
  }, [coinStatus, recipientUser]);
    
  useEffect(() => {
    // Fetch coin information when itemId changes
    const fetchCoinInfo = async () => {
      try {
        // First API call to get chat data
        const response = await axios.get(`${baseUrl}/chat/${user?.id}`);
        console.log(response);
        
        if (response.data) {
          // Find the object in the response data that matches the chatId
          const matchedChat = response.data.find(chat => chat.id === chatId);
  
          if (matchedChat) {
            const { item_id } = matchedChat;
  
            // Second API call to get coin name based on the item_id
            const coinResponse = await axios.get(`${baseUrl}/coins/getcoins/${item_id}`);
            console.log(coinResponse);
            setCoinInfo(response.data)
            if (coinResponse.data) {
              // Set the coin name from the response
              setcoinName(coinResponse.data.name); // Assuming 'name' is the property with the coin's name
            } else {
              setcoinName(''); // Handle if no coin data is found
            }
  
            // Set the members or other details
            setcoinStatus(matchedChat.status);
          } else {
            setcoinName('');
            setcoinStatus('');
          }
        } else {
          setCoinInfo(null); // or set a default state
        }
      } catch (error) {
        console.error('Error fetching coin info:', error);
      }
    };
  
    if (itemId) {
      fetchCoinInfo();
    }
  }, [itemId, chatId, user]);
  

  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);


  if (!recipientUser) {
    return (
      <p style={{ textAlign: "center", width: "100%" }}>
        No Conversation Selected Yet.....
      </p>
    );
  }

  if (isMessageLoading) {
    return (
      <p style={{ textAlign: "center", width: "100%" }}>
        Loading Chat.....
      </p>
    );
  }

  return (
    <Stack gap={4} className="chat-box">
      <div className="chat-header">
        <strong>{recipientUser?.name}</strong>
      </div>
 
<div className="admin-account-details">
<h2 style={{ color: '#FFD700', fontSize: '1.5rem', fontWeight: 'bold' }}>Admin Account Details</h2>

  {accountData ? (
    Object.keys(accountData).length > 0 ? (
      Object.keys(accountData).map((type) => (
        <div key={type}>
       <h3 style={{ fontSize: '1.2rem', color: '#CCCCCC' }}>{type.toUpperCase()}</h3> {/* Lighter text for dark bg */}
       <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
       {accountData[type].map((account) => (
          <li key={account.acc_id} style={{ marginBottom: '10px', padding: '10px', backgroundColor: '#333333', border: '1px solid #555', borderRadius: '5px' }}> {/* Darker gray background */}
            <strong style={{ fontWeight: 'bold', color: '#1E90FF' }}>Name:</strong> {account.acc_name} <br /> {/* Bright blue for emphasis */}
            <strong style={{ fontWeight: 'bold', color: '#1E90FF' }}>Identification:</strong> {account.identification}
          </li>
        ))}
          </ul>
        </div>
      ))
    ) : (
      <p style={{ color: '#AA0000', fontSize: '1.2rem' }}>
        No accounts found for this admin.
      </p>
    )
  ) : (
    <p style={{ color: '#AA0000', fontSize: '1.2rem' }}>
        No accounts found for this admin.
    </p>
  )}
</div>

      {coinInfo && (
        <div className="coin-info p-4 bg-gray-800 text-white rounded-lg mb-4">
          <h4 className="text-yellow-400 text-sm font-medium mb-1">Coin Information:</h4>
          <p><strong>Name:</strong> {coinName}</p>
          {/* <p><strong>Price:</strong> {coinInfo.buy_price}</p> */}

          {coinStatus === 'open' ? (
            <>
      
              <p><strong>Status:</strong> Trading is currently open for this coin.</p>
     

              <p><strong>Only trade {coinName} here. If you need to communicate about other coins, make sure to go back and select the coin you desire from the table on the dashboard.</strong></p>
              <p>Thanks for your cooperation!</p>
            </>
          ) : coinStatus === 'closed' ? (
            <>
              <p><strong>Status:</strong> Trading is currently closed for this coin.</p>
              <p>This trading chat for {coinName} is closed at the moment. Please check back later or select another coin from the table on the dashboard.</p>
              <p>We appreciate your understanding.</p>
            </>
          ) : coinStatus === 'terminate' ? (
            <>
              <p><strong>Status:</strong> This trading chat has been terminated.</p>
              <p>Trading for {coinName} is no longer available. Please select another coin from the table on the dashboard if you wish to continue trading.</p>
              <p>We apologize for any inconvenience caused.</p>
            </>
          ) : (
            <p><strong>Status:</strong> Unknown status.</p>
          )}
        </div>
      )}

      <Stack gap={3} className="messages">
       {Array.isArray(messages) && messages.map((message) => (
          <Stack
            key={message.id}
            className={`message flex-grow-0 ${message.senderId === String(user.id) ? 'self ' : 'align-self-start'}`}
            ref={scroll}
          >
            {message.image && (
              <img
                src={message.image}
                alt="sent content"
                className="sent-image"
                style={{ maxWidth: '100%', borderRadius: '8px', marginBottom: '5px' }}
              />
            )}
            <span>{message.text}</span>
            <span className="message-footer">
              {moment(message.created_at).calendar()}
            </span>
          </Stack>
        ))}
      </Stack>
        <InputEmoji 
          value={textMessage} 
          onChange={setTextMessage} 
          fontFamily='nunito' 
          borderColor='rgba(72,112,223,0.2)' 
          className="flex-grow"
        />
        {isSending && (
          <div className="flex justify-end mt-1">
            <div className="message p-1 rounded-md max-w-xs bg-gray-300 dark:bg-gray-700 text-black dark:text-gray-200">
              Sending...
            </div>
          </div>
        )}
<Stack direction='horizontal' gap={3} className='chat-input flex-grow-0 w-full'>
  <input
    type="file"
    accept="image/*"
    onChange={(e) => setImage(e.target.files[0])}
    className="file-input hidden bg-gray-500 text-white p-3 rounded-md flex items-center justify-center"
  />
  <button
  className="send-btn"
  style={{
    backgroundColor: '#1E90FF', // Custom blue color
    color: 'white',
    padding: '10px 15px', // Adjust padding for a better look
    borderRadius: '20%', // Make it circular
    width:"15%",
    maxWidth:"22%",
    border: 'none', // Remove any border
    cursor: 'pointer', // Add pointer cursor for interaction
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional shadow for better visual
    touchAction: 'manipulation',
  }}
  onClick={() => {
    sendTextMessage
    (chatId, textMessage, String(user.id), currentChat?.[0]?.id, image, setTextMessage).finally(() => setIsSending(false));
    setImage(null);}}

>
  Send
</button>


</Stack>

    </Stack>
  );
};

export default ChatBox;
