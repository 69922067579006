import { makeStyles,Container, Typography } from '@material-ui/core'
import React from 'react'
import Carousel from './Carousel';


const useStyles=makeStyles(()=>({

    bannerContent:{
      height:300,
      display:'flex',
      flexDirection:"column",
      paddingTop:25,
      marginBottom:"25%",
        justifyContent:"space-around"
        
      },
      tagline:{
        display:"flex",
        height:"100%",
        flexDirection:"column",
        justifyContent:"center",
        textAlign:"center",
    }
}))
const Banner = () => {
    const classes=useStyles();
  return (
    <div className={classes.banner}>
        <Container className={classes.bannerContent}>
<div className={classes.tagline}>
    <Typography variant="h2" style={{
        fontWeight:"bold",
        marginBottom:5,
        fontFamily:"Montserrat",
    }}>
            TAUSCH
    </Typography>

    <Typography variant="subtitle2" style={{
        color:"darkgrey",
        textTransform:"capitalize",
        fontFamily:"Montserrat"
    }}>
        Exchange with less limitations
    </Typography>

</div>
<Carousel/>
        </Container>
    </div>
  )
}

export default Banner