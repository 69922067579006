import React, { useContext, useEffect, useState } from 'react';
import { ChatContext } from '../context/ChatContext';
import { Stack, Container } from '@mui/material';
import UserChat from '../components/chat/UserChat';
import CryptoCtx, { useCryptoState } from '../CryptoCtx';
import ChatBox from '../components/chat/ChatBox';
import { useLocation } from 'react-router-dom';

const Chat = () => {
  const { user } = useCryptoState();
  const { userChats, isUserChatsLoading, updateCurrentChat } = useContext(ChatContext);
  const location = useLocation();
  const { data } = location.state || {};
  const [selectedChatId, setSelectedChatId] = useState(data?.id || null);
  const [itemId, setItemId] = useState(data?.id || null);

  useEffect(() => {
    if (data) {
      setItemId(data?.id || null);
    }
  }, [data]);

  const uniqueChats = userChats.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, []);

  const handleChatClick = (chat) => {
    updateCurrentChat(chat);
    setSelectedChatId(chat[0]?.id);
    setItemId(chat[0]?.item_id || null);
  };

  return (
    <Container className="flex flex-col h-screen p-4 bg-gray-100 dark:bg-gray-900">
      {userChats?.length < 1 ? (
        <div>No available chats at the moment...</div>
      ) : (
        <Stack direction="row" className="flex-grow">
          <div className="w-1/3 max-w-xs overflow-y-auto bg-white dark:bg-gray-800 rounded-lg shadow-md">
            {isUserChatsLoading && <p>Loading chats ...</p>}
            {uniqueChats?.map((chat, index) => {
              const chatArray = Array.isArray(chat) ? chat : [chat];
              return (
                <div
                  key={index}
                  onClick={() => handleChatClick(chatArray)}
                  className="flex items-center p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  <UserChat chat={chatArray} user={user} />
                </div>
              );
            })}
          </div>
          <div className="flex-grow p-4 overflow-y-auto bg-gray-200 dark:bg-gray-700 rounded-lg">
            <ChatBox chatId={selectedChatId} itemId={itemId} />
          </div>
        </Stack>
      )}
    </Container>
  );
};

export default Chat;
